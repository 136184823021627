import { render, staticRenderFns } from "./LessonComponentLayout.vue?vue&type=template&id=2d22aaae&scoped=true&"
import script from "./LessonComponentLayout.vue?vue&type=script&lang=js&"
export * from "./LessonComponentLayout.vue?vue&type=script&lang=js&"
import style0 from "./LessonComponentLayout.vue?vue&type=style&index=0&id=2d22aaae&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d22aaae",
  null
  
)

component.options.__file = "LessonComponentLayout.vue"
export default component.exports