<template>
    <div class="layout-section flex-container" v-cloak>
        <div class="components-container" v-cloak >
            <div class="lesson-banner-component flex-container">
                <span>{{$t('lessonBanner')}}</span>
            </div>
            <draggable class="draggable-section"  v-model="components" :options="dragOptions"
             :move="onMove" @change="onChange">
                <transition-group name="flip-list" tag="ul">
                    <li  v-for="component in components" :key="component.id" >
                        <lesson-component :component="component"/>  
                    </li>
                </transition-group>
            </draggable>
        </div>
        <components-catalog/>
    </div>
</template>
<script>
import LessonComponentLayout from '@/components/BackOffice/LessonBuilder/LessonComponentLayout.vue'
import ComponentsCatalog from '@/components/BackOffice/LayoutComponents/ComponentsCatalog.vue'
import LayoutSectionMixin from '@/components/BackOffice/Mixins/LayoutSectionMixin.js'
import Factory from '@/utils/ContentFactory.js'
export default {
    name: 'LessonLayoutSection',
    mixins: [LayoutSectionMixin],
    components:{  
        'lesson-component': LessonComponentLayout, 
        'components-catalog':ComponentsCatalog, 
    },
    methods:{ 
        onChange({added, moved}){
            if(added)
            {
                let paragraph = new Factory.CreateParagraph(added.newIndex +1, added.element)
                paragraph.lessonId = this.$route.params.lessonId
                this.$store.dispatch('createLessonBuilderComponent', paragraph)
            }
            if(moved){
                let component = this.components.find(e => e.id === moved.element.id)
                this.$store.dispatch('reoderLessonComponent', component)
            }
        },
    }, 
    computed:{
        components:{
            get()
            {
                return this.$store.state.LessonBuilderComponents
            },
            set(value){
                let components = value.filter(e => e.id)
                this.$store.commit('updateLessonComponentsOrder', components)
            }
        }
    }

}
</script>

<style lang="less" scoped>

.layout-section{
    flex-direction: row;
    .components-container{
        &::-webkit-scrollbar{
            display: none;
        }
        .lesson-banner-component{
            margin-left: 0;
            span{
                text-transform: uppercase;
            }
        }
    }

    & /deep/ .drag-drop-elements{
        height: calc(100vh - 166px);
        overflow-y: auto;

        &::-webkit-scrollbar{
            display: none;
        }
    }
}

.components-catalog{
    height: calc(100vh - 110px);
}

</style>


