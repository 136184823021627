<template>
    <div class="lesson-builder builder custom-scrollbar">
        <component :is="currentSection"/>
    </div>    
</template>
<script>
import LessonBuilderLayoutSection from '@/components/BackOffice/LessonBuilder/LessonBuilderLayoutSection.vue'
import LessonBuilderContentSection from '@/components/BackOffice/LessonBuilder/LessonBuilderContentSection.vue'
import BuilderMixin from '@/views/BackOffice/BuilderMixin.js'

export default {
    name: 'LessonBuilder',
    mixins:[BuilderMixin], 
    components:{
        'layout-section': LessonBuilderLayoutSection, 
        'content-section': LessonBuilderContentSection
    },
    created(){
        this.isLoadingInformation = true
        this.$store.commit('loadLessonBuilderComponents', null)
        this.$store.dispatch('loadLessonBuilderComponents', this.$route.params.lessonId)
            .finally(()=> this.isLoadingInformation = false)
        this.$store.commit('updateSwitchButtonDisplayStatus', true)
    }

}
</script>
<style lang="less" scoped>
    .lesson-builder{
        margin-top: 104px;
        height: calc(100vh - 104px);
    }
</style>



